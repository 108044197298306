<template>
    <section>
        <b-card>
        <template #header>
            <div class="d-flex align-items-center">
            <span class="text-primary">Newsletter </span>
            <b-breadcrumb class="ml-2">
                <b-breadcrumb-item>
                <feather-icon
                    icon="HomeIcon"
                    scale="1.25"
                    shift-v="1.25"
                ></feather-icon>
                </b-breadcrumb-item>
                <b-breadcrumb-item @click="$router.push('/newsletter/recipient/list')"> Liste destinatari</b-breadcrumb-item>
                <b-breadcrumb-item active>Dettaglio Lista</b-breadcrumb-item>
            </b-breadcrumb>
            </div>
        </template>

        <b-row class="mt-2">
            <b-col md="9"> 
                <h5 class="text-primary-light"><b>NOME DELLA LISTA</b>: nome lista</h5>

                <b-button-group class="mt-1 mb-3">
                    <b-button variant="primary-light" :active="step == 1" @click="step = 1">
                        Importa Indirizzi
                    </b-button>
                    <b-button variant="primary-light" :active="step == 2" @click="step = 2">
                        Carica Indirizzo
                    </b-button>
                    <b-button variant="primary-light" :active="step == 3" @click="step = 3">
                        Esporta Excel
                    </b-button>
                </b-button-group>
            </b-col>

            <b-col md="3">
            <div class="d-flex align-items-center mb-2" v-if="step == 0">
                <span class="mr-1">Cerca</span>
                <b-form-input type="text" v-model="filter" size="md" />
            </div>
            </b-col>
        </b-row>

        <div v-if="step==0">

        <b-table :fields="fields" :items="recipients" :filter="filter" striped show-empty responsive>
            <template #cell(id)="data">
                {{data.item.id_lista}}
            </template>
            <template #cell(nome)="data">
                <feather-icon icon="FileTextIcon" class="cursor-pointer text-primary-light" size="20"/> {{data.item.nome_lista}}
            </template>
            <template #cell(indirizzi)="data">
                {{data.item.count_email}} E-mail
            </template>
            <template #cell(note)="data">
                {{data.item.note_lista}}
            </template>
            <template #head(opzioni)>
                <span></span>
            </template>
            <template #cell(opzioni)>
                <feather-icon icon="Trash2Icon" class="cursor-pointer text-danger" size="20" />
            </template>
        </b-table>

        </div>

        <div v-if="step == 1">
            <h3 class="text-primary-light">
                Pannello di importazione indirizzi per newsletter
            </h3>

            <p class="my-2">
                Disponendo di un <b>foglio Excel</b> potrai importare nuovi indirizzi da aggiungere alla singola lista che <br>
                stai modificando. Fai <b>copia e incolla</b> dal foglio Excel, inserendo i nuovi contatti nel campo di testo <br>
                che segue. Una volta finito ti basterà <b>importare</b>!
            </p>

            <b-form-textarea rows="12" class="border border-primary-light" v-model="import_textarea">

            </b-form-textarea>

            <b-button class="mt-2" variant="primary" @click="import_textarea = ''">
                Cancella tutto
            </b-button>

        </div>

        <div v-if="step == 2">
            <h3 class="text-primary-light">
                Pannello di inserimento nuovo iscritto alla newsletter
            </h3>

            <p class="my-2">
                Da questo pannello potrai caricare manualmente un nuovo indirizzo da aggiungere alla <br>
                newsletter che stai modificando. Compila tutti i campi e poi inserisci il nuovo iscritto. <br>
                Per procedere all'inserimento è <b>necessario conoscere l'indirizzo e-mail</b> del contatto.
            </p>

            <b-row class="my-3">
                <b-col md="6">
                    <b-form-group label="Nome nuovo contatto">
                        <b-form-input placeholder="Inserisci il nome del nuovo contatto...">

                        </b-form-input>
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group label="Cognome nuovo contatto">
                        <b-form-input placeholder="Inserisci il cognome del nuovo contatto...">

                        </b-form-input>
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group label="Ragione sociale">
                        <b-form-input placeholder="Inserisci la ragione sociale del nuovo contatto...">

                        </b-form-input>
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group label="Indirizzo e-mail">
                        <b-form-input placeholder="Inserisci l'indirizzo e-mail del nuovo contatto...">

                        </b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>

        </div>

        </b-card>

        <div class="fixed-bottom bg-white p-2">
      <div class="text-right">
        <div>
          <b-button
            v-if="step != 0"
            variant="outline-primary mr-2"
            @click="step = 0"
            >Torna Indietro</b-button
          >
          <b-button v-if="step == 1" variant="primary">
            Importa indirizzi
          </b-button>
          <b-button v-else-if="step == 2" variant="primary"> Carica indirizzo </b-button>
        </div>
      </div>
    </div>
      
    </section>
</template>

<script>

import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
import {BButton, BButtonGroup, BRow, BCol, BBreadcrumb, BBreadcrumbItem, BCard, BTable, BFormInput, BFormTextarea, BFormGroup} from 'bootstrap-vue';

import {Requests} from '@/api';

export default {
    components: {
        BButton,
        BButtonGroup,
        BRow,
        BCol,
        BBreadcrumb,
        BBreadcrumbItem,
        BCard,
        BTable,
        BFormInput,
        BFormTextarea,
        BFormGroup,
        LayoutVertical,
    },

    data: () => ({
         fields: [
            {
                key: 'id',
                sortable: true,
            },
            {
                key: 'nome',
                sortable: true,
            },
            {
                key: 'indirizzi',
                sortable: true,
            },
            {
                key: 'data_creazione',
                sortable: true,
            },
            {
                key: 'note',
                sortable: true,
            },
            {
                key: 'opzioni',
                sortable: false,
            },
        ],

        filter: null,
        recipients: [],

        import_textarea: '',

        //Helpers

        loading: false,
        step: 0,
    }),

    created() {
        this.getRecipientDetails();
    },

    methods: {
        async getRecipientDetails() {
            this.loading = true;

            let request_data = new FormData();

            request_data.append('id_lista', this.$route.params.id)

            try {
                const response = await Requests.getRecipientDetails(request_data);
                this.recipients = response;
            } catch(err) {
                console.debug(err)
            }

            this.loading = false;
        }
    }
}
</script>