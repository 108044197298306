import dealer_items from "./dealer";

export default [
  {
    title: "Dashboard",
    route: "dashboard",
    resource: "manager",
    action: "read",
  },
  {
    title: "Cerca auto",
    icon: "cerca-auto",
    route: "search_car",
    resource: "shared",
    action: "read",
  },
  {
    title: "Parco auto",
    icon: "parco-auto.svg",
    resource: "shared",
    action: "read",
    children: [
      {
        title: "Aggiungi auto",
        route: "carfleet_addcar",
        resource: "manager",
        action: "read",
      },
      {
        title: "Aggiungi da Eurotax",
        route: "carfleet_login_eurotax",
        resource: "manager",
        action: "read",
      },
      {
        title: "Da pubblicare",
        route: "carfleet_topublish",
        resource: "manager",
        action: "read",
      },
      {
        title: "In vendita",
        route: "carfleet_onsale",
        resource: "manager",
        action: "read",
      },
      {
        title: "In vendita al privato",
        route: "seller_carfleet_sale_privates",
        resource: "seller",
        action: "read",
      },
      {
        title: "In vendita al rivenditore",
        route: "seller_carfleet_sale_resellers",
        resource: "seller",
        action: "read",
      },
      {
        title: "Vendute",
        route: "carfleet_sold",
        resource: "manager",
        action: "read",
      },
      {
        title: "Riservate",
        route: "carfleet_reserved",
        resource: "manager",
        action: "read",
      },
      {
        title: "In arrivo",
        route: "carfleet_incoming",
        resource: "manager",
        action: "read",
      },
      {
        title: "Da verificare",
        route: "carfleet_toverify",
        resource: "manager",
        action: "read",
      },
      {
        title: "Senza foto",
        resource: "manager",
        action: "read",
      },
      {
        title: "Senza foto perizia",
        resource: "manager",
        action: "read",
      },
    ],
  },
  {
    title: "Carstock",
    icon: "carstock.svg",
    children: [
      {
        title: "Crea Carstock",
        route: "carstock_create",
        resource: "manager",
        action: "read",
      },
      {
        title: "Elenco Carstock",
        route: "carstock_list",
        resource: "manager",
        action: "read",
      },
    ],
  },
  {
    title: "Elenco Carstock",
    icon: "elenco",
    route: "carstock_list",
    resource: "seller",
    action: "read",
  },
  {
    title: "Multipubblicazione",
    icon: "multipubblicazione.svg",
    children: [
      {
        title: "Seleziona auto",
        route: "multipublishing_vehicles",
        resource: "manager",
        action: "read",
      },
      {
        title: "Canali di pubblicazione",
        route: "multipublishing_channels",
        resource: "manager",
        action: "read",
      },
    ],
  },
  {
    title: "Fornitori",
    icon: "fornitori",
    route: "suppliers_list",
    resource: "manager",
    action: "read",
  },
  {
    title: "Clienti",
    icon: "clienti.svg",
    children: [
      {
        title: "Elenco clienti",
        route: "customer_list",
        resource: "manager",
        action: "read",
      },
      {
        title: "Cerca cliente",
        route: "customer_search",
        resource: "manager",
        action: "read",
      },
      {
        title: "Aggiungi cliente",
        route: "customer_add",
        resource: "manager",
        action: "read",
      },
    ],
  },
  {
    title: "Gst. del personale",
    icon: "personale.svg",
    children: [
      {
        title: "Account Manager",
        route: "account_manager_list",
        resource: "manager",
        action: "read",
      },
      {
        title: "Account Venditori",
        route: "account_seller_list",
        resource: "manager",
        action: "read",
      },
      {
        title: "Account Responsabili Logistica",
        route: "account_logistics_list",
        resource: "manager",
        action: "read",
      },
      {
        title: "Account Backoffice",
        route: "account_backoffice_list",
        resource: "manager",
        action: "read",
      },
    ],
  },
  {
    title: "Rivenditori",
    icon: "rivenditori",
    route: "resellers_list",
    resource: "manager",
    action: "read",
  },
  {
    title: "Altri report contabili",
    icon: "altri-report.svg",
    children: [
      {
        title: "Carico e Scarico",
        route: "other_reports_load_unload",
        resource: "manager",
        action: "read",
      },
      {
        title: "Conto esposizione",
        route: "other_reports_exposure",
        resource: "manager",
        action: "read",
      },
      {
        title: "Auto in giacenza",
        route: "other_reports_car_in_stock",
        resource: "manager",
        action: "read",
      },
    ],
  },
  {
    title: "Newsletter",
    icon: "newsletter.svg",
    children: [
      {
        title: "Campagne",
        route: "newsletter_campaign_list",
        resource: "manager",
        action: "read",
      },
      {
        title: "Liste destinatari",
        route: "newsletter_recipient_list",
        resource: "manager",
        action: "read",
      },
      {
        title: "Template",
        route: "newsletter_template_list",
        resource: "manager",
        action: "read",
      },
      {
        title: "Statistiche",
        route: "newsletter_statistics",
        resource: "manager",
        action: "read",
      },
    ],
  },
  {
    title: "Report",
    icon: "report.svg",
    children: [
      {
        title: "Rep. Accessi",
        route: "report_access",
        resource: "manager",
        action: "read",
      },
      {
        title: "Rep. Auto Vendute",
        route: "report_auto",
        resource: "manager",
        action: "read",
      },
      {
        title: "Dettaglio Auto vendute",
        route: "report_sales",
        resource: "manager",
        action: "read",
      },
      {
        title: "Rep. Vendite venditori",
        route: "report_seller_sales",
        resource: "manager",
        action: "read",
      },
      {
        title: "Rep. Acquisti venditori",
        route: "report_seller_buy",
        resource: "manager",
        action: "read",
      },
    ],
  },
  {
    title: "Preventivi",
    icon: "preventivi",
    route: "estimates_list",
    resource: "manager",
    action: "read",
  },
  {
    title: "Contratti privati",
    icon: "contratti-privati.svg",
    children: [
      {
        title: "Contratti Approvati",
        route: "contracts_private_approved_list",
        resource: "shared",
        action: "read",
      },
      {
        title: "Proposte d'acquisto",
        route: "contracts_private_pending_list",
        resource: "shared",
        action: "read",
      },
      {
        title: "Contratti Annullati",
        route: "contracts_private_denied_list",
        resource: "shared",
        action: "read",
      },
    ],
  },
  {
    title: "Contratti rivenditori",
    icon: "contratti-rivenditori.svg",
    children: [
      {
        title: "Contratti Approvati",
        route: "contracts_reseller_approved_list",
        resource: "shared",
        action: "read",
      },
      {
        title: "Proposte d'acquisto",
        route: "contracts_reseller_pending_list",
        resource: "shared",
        action: "read",
      },
      {
        title: "Contratti Annullati",
        route: "contracts_reseller_denied_list",
        resource: "shared",
        action: "read",
      },
    ],
  },
  {
    title: "CRM",
    icon: "crm.svg",
    children: [
      {
        title: "Agenda",
        route: "crm_agenda",
        resource: "shared",
        action: "read",
      },
      {
        title: "Leads",
        route: "crm_leads",
        resource: "shared",
        action: "read",
      },
      {
        title: "Attività",
        route: "crm_activities",
        resource: "shared",
        action: "read",
      },
      {
        title: "Segnala Trattativa",
        resource: "shared",
        action: "read",
      },
      {
        title: "Trattative Effettuate",
        resource: "shared",
        route: "crm_negotiations",
        action: "read",
      },
      {
        title: "Statistiche",
        resource: "shared",
        action: "read",
      },
    ],
  },
  {
    title: "Impostazioni",
    icon: "impostazioni.svg",
    resource: "manager",
    action: "read",
    children: [
      {
        title: "Gestione Servizi",
        route: "settings_services",
        resource: "manager",
        action: "read",
      },
      {
        title: "Gestione Accessori",
        route: "settings_accessories",
        resource: "manager",
        action: "read",
      },
      {
        title: "Gestione Agenzie Partner",
        route: "settings_partners",
        resource: "manager",
        action: "read",
      },
      {
        title: "Gestione Tipologie Pratiche",
        route: "settings_practices",
        resource: "manager",
        action: "read",
      },
      {
        title: "Gestione Finanziarie",
        route: "settings_financial",
        resource: "manager",
        action: "read",
      },
      {
        title: "Gestione Ubicazioni",
        route: "settings_locations",
        resource: "manager",
        action: "read",
      },
    ],
  },
  ...dealer_items,
];
