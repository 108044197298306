export default [
  {
    title: "Dashboard",
    route: "dealer_dashboard",
    resource: "dealer",
    action: "read",
  },
  {
    title: "Auto in vendita",
    icon: "parco-auto",
    route: "cars_on_sale",
    resource: "dealer",
    action: "read",
  },
  {
    title: "Elenco ordini",
    icon: "elenco",
    route: "orders",
    resource: "dealer",
    action: "read",
  },
  {
    title: "Crea avviso",
    icon: "avviso",
    route: "announcements_create",
    resource: "dealer",
    action: "read",
  },
  {
    title: "I miei avvisi",
    icon: "miei-avvisi",
    route: "announcements",
    resource: "dealer",
    action: "read",
  },
  {
    title: "Auto che ho riservato",
    icon: "auto-riservate",
    route: "reserved_cars",
    resource: "dealer",
    action: "read",
  },
  {
    title: "Auto preferite",
    icon: "favorite",
    route: "favorite_cars",
    resource: "dealer",
    action: "read",
  },
  {
    title: "Chiedi supporto",
    icon: "support",
    route: "chat",
    resource: "dealer",
    action: "read",
  },
];
